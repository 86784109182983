import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { rhythm } from "../../utils/typography"
import "./hannin.css"

const importantStyle = {
  fontWeight: "bolder",
  fontSize: "1.0em",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
}

const separatorStyle = {
  textAlign: "center",
}

const criminalColor = "#0E7DC2"
const crimeReporterColor = "#F35854"
const detectiveColor = "#4B5FBE"
const alibiColor = "#9E57A3"
const accompliceColor = "#345DB9"

const cards = [
  {
    color: criminalColor,
    japaneseName: {
      kanji: "犯人",
      furigana: "はんにん"
    },
    englishName: "Criminal",
    explanation: <>
        <span>Play this card to win the game. If asked, you must answer truthfully that you are the criminal unless you are also holding an alibi card.</span>
      </>,
    importantText: "You may only play this card when it is the last card in your hand.",
    count: 0,
  },
  {
    color: crimeReporterColor,
    japaneseName: {
      kanji: "第一発見者",
      furigana: "だいいちはっけんしゃ"
    },
    englishName: "Crime Reporter",
    explanation: <>
        <span>When you have this card, you start the game by playing it first and describing the crime you witnessed.</span>
      </>,
    count: 0,
  },
  {
    color: detectiveColor,
    japaneseName: {
      kanji: "探偵",
      furigana: "たんてい"
    },
    englishName: "Detective",
    explanation: <>
        <span>Play this card to accuse someone of being the criminal. You point to that play and ask them if they are the criminal. They must answer truthfully unless you also hold an alibi card. If they admit they are the criminal, the players who did not play the accomplice card and are not holding the criminal card win!</span>
      </>,
    importantText: "You may only play this card from the 2nd round (when you have 3 or less cards).",
    count: 0,
  },
  {
    color: alibiColor,
    japaneseName: {
      kanji: "アリバイ"
    },
    englishName: "Alibi",
    explanation: <>
        <span>When you play this card, nothing happens.</span>
      </>,
    importantText: "If you are holding this card in addition to the criminal card, you lie when asked by the detective if you are the criminal.",
    count: 0,
  },
  {
    color: accompliceColor,
    japaneseName: {
      kanji: "たくらみ"
    },
    englishName: "Accomplice",
    explanation: <>
        <span>Play this card to permanently join the criminal's team. If the criminal wins, you win. If the criminal loses, you lose.</span>
      </>,
    importantText: "Keep the card in front of you instead of in the play pile.",
    count: 0,
  },
  {
    color: "#F45F99",
    japaneseName: {
      kanji: "うわさ"
    },
    englishName: "Rumor",
    explanation: <>
        <span>When you play this card, everyone takes 1 card randomly from the person on their right at the same time.</span>
      </>,
    count: 0,
  },
  {
    color: "#90BE5B",
    japaneseName: {
      kanji: "情報操作",
      furigana: "じょうほうそうさ"
    },
    englishName: "Propaganda",
    explanation: <>
        <span>When you play this card, everyone picks 1 card from their hand and gives it to the person on their left at the same time.</span>
      </>,
    count: 0,
  },
  {
    color: "#2E7E59",
    japaneseName: {
      kanji: "取り引き",
      furigana: "とりひき"
    },
    englishName: "Exchange",
    explanation: <>
        <span>When you play this card, choose another player, then each of you choose 1 card and exchange it with each other.</span>
      </>,
    count: 0,
  },
  {
    color: "#0791C2",
    japaneseName: {
      kanji: "目撃者",
      furigana: "もくげきしゃ"
    },
    englishName: "Witness",
    explanation: <>
        <span>When you play this card, pick one person to look at their hand secretly.</span>
      </>,
    count: 0,
  },
  {
    color: "#EBC74F",
    japaneseName: {
      kanji: "少年",
      furigana: "しょうねん"
    },
    englishName: "Boy Detective",
    explanation: <>
        <span>Play this card to know who the criminal is by saying the following and keeping your own eyes open:</span>
        <ul>
          <li>Everyone close your eyes</li>
          <li>Person who has the criminal card, open your eyes</li>
          <li>Close your eyes</li>
          <li>Everyone, open your eyes</li>
        </ul>
      </>,
    importantText: "Even if the criminal has an alibi card, they must open their eyes.",
    count: 0,
  },
  {
    color: "#C76C59",
    japaneseName: {
      kanji: "いぬ"
    },
    englishName: "Dog",
    explanation: <>
        <span>When you play this card, you first pick a person and then pick a card out of their hand and show it to everyone. If the card is the criminal card, you alone win! If not, everyone now knows one card in that player's hand.</span>
      </>,
    count: 0,
  },
  {
    color: "#5BB7F6",
    japaneseName: {
      kanji: "一般人",
      furigana: "いっぱんじん"
    },
    englishName: "Bystander",
    explanation: <>
        <span>When you play this card, nothing happens.</span>
      </>,
    count: 0,
  }
]

const cardContainerStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
}

const cardStyle = {
  width: "200px",
  flexGrow: 1,
  color: "white",
  border: "2px solid black",
  borderRadius: "5px",
  paddingLeft: rhythm(0.5),
  paddingRight: rhythm(0.5),
  paddingBottom: rhythm(1.0),
  paddingTop: rhythm(1.0),
  margin: rhythm(0.25),
  display: "flex",
  flexDirection: "column",
}

const cardHeaderStyle = {
  margin: 0,
  color: "white",
  fontWeight: "bold",
  fontSize: rhythm(1.0),
}

class Card extends React.Component {
  render() {
    const { color, japaneseName, englishName, explanation, importantText } = this.props

    return (
      <div class="card" style={{...cardStyle, backgroundColor: color}}>
        <div style={{paddingBottom: rhythm(0.75), borderBottom: "1px white solid"}}>
          <h2 style={cardHeaderStyle}>
            <ruby>
              {japaneseName.kanji}
              {japaneseName.furigana && <rt>{japaneseName.furigana}</rt>}
            </ruby>
          </h2>
          <h2 style={cardHeaderStyle}>{englishName}</h2>
        </div>
        <div style={{paddingTop: rhythm(0.75)}}>
          {explanation}
        </div>
        { importantText && 
          <div style={importantStyle}>
            <div style={{alignSelf: "end"}}>
              <span style={separatorStyle}>~ ~ ~</span>
              <span>{importantText}</span>
            </div>
          </div>
        }
      </div>
    )
  }
}

class MainIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const cardComponents = cards.map(card => {
      return <Card {...card}/>
    })

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`Hannin ha/wa Odoru 3rd Edition English Rules`}
          keywords={[`English`, `board games`, `card games`, `Japanese`, `hannin ha odoru`, `hannin wa odoru`, `hannin`, `odoru`, `3rd edition`, `lying games`]}
        />
        <h2 style={{ marginTop: rhythm(1.0) }}>English Rules for Hannin ha/wa Odoru 3rd Edition</h2>
        <Bio />
        <p>
          I love this game and noticed there was no English version of the 3rd edition that I could find. I often play this game at various board game events that I host and not everyone can speak/read Japanese, so I created this page to make it easier to play together!
        </p>
        <p>
          The game begins by passing out 4 cards to each player (these are chosen based on the chart at the bottom, just use all the cards if there are 8 of you). The player with the crime reporter card plays it first and tells everyone the crime they saw. Then players take turns clockwise, playing 1 card per turn. The game ends when either the criminal card is played or the criminal is found by the detective or dog card.
        </p>
        <div class="cards" style={cardContainerStyle}>
          {cardComponents}
        </div>
        <div style={{position: "relative"}}>
        <div style={{overflowX: "auto"}}>
          <table>
            <tr>
              <th>
                # of players
              </th>
              <th style={{backgroundColor:criminalColor, color:"white"}}>
                # of Criminal cards
              </th>
              <th style={{backgroundColor:crimeReporterColor, color:"white"}}>
                # of Crime Reporter cards
              </th>
              <th style={{backgroundColor:detectiveColor, color:"white"}}>
                # of Detective cards
              </th>
              <th style={{backgroundColor:alibiColor, color:"white"}}>
                # of Alibi cards
              </th>
              <th style={{backgroundColor:accompliceColor, color:"white"}}>
                # of Accomplice cards
              </th>
              <th>
                # of other random cards
              </th>
            </tr>
            <tr>
              <td>
                3 players
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                0
              </td>
              <td>
                8
              </td>
            </tr>
            <tr>
              <td>
                4 players
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                11
              </td>
            </tr>
            <tr>
              <td>
                5 players
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                2
              </td>
              <td>
                1
              </td>
              <td>
                14
              </td>
            </tr>
            <tr>
              <td>
                6 players
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                2
              </td>
              <td>
                2
              </td>
              <td>
                2
              </td>
              <td>
                16
              </td>
            </tr>
            <tr>
              <td>
                7 players
              </td>
              <td>
                1
              </td>
              <td>
                1
              </td>
              <td>
                2
              </td>
              <td>
                3
              </td>
              <td>
                2
              </td>
              <td>
                19
              </td>
            </tr>
            <tr>
              <td>
                8 players
              </td>
              <td>
                All
              </td>
              <td>
                All
              </td>
              <td>
                All
              </td>
              <td>
                All
              </td>
              <td>
                All
              </td>
              <td>
                All
              </td>
            </tr>
          </table>
        </div>
        </div>
      </Layout>
    )
  }
}

export default MainIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
